import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'

import loadable from '@loadable/component'

const Layout = loadable(() => import('../components/Layout'))
const SEO = loadable(() => import('../components/Seo'))
const PageBuilder = loadable(() => import('../components/PageBuilder'))

export const query = graphql`
  query PagePageQuery($_id: String!) {
    page: sanityPage(_id: { eq: $_id }) {
      title
      _rawPageBuilder(resolveReferences: { maxDepth: 10 })
      seo {
        description
        image {
          asset {
            gatsbyImageData(height: 1200, width: 600)
          }
        }
      }
    }
  }
`

const PageSingle = ({ location, data: { page }, pageContext }) => {
  const _id = pageContext._id.replace('__i18n_it', '')
  const hrefLang = pageContext.allHrefLangs.filter(lang => lang._id === _id)[0].langs

  return (
    <>
      <SEO title={page.title} description={page.seo?.description} hrefLang={hrefLang} />
      <Layout location={location || ''}>
        <PageBuilder location={location} pageBuilder={page._rawPageBuilder} />
      </Layout>
    </>
  )
}

export default PageSingle

PageSingle.propTypes = {
  location: PropTypes.object,
  data: PropTypes.object,
}